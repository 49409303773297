<template>
  <div>
    <b-container class="base-container-x pt-2" style="min-height:400px;background-color:#fff">
      <b-row class="mb-5 justify-content-center">
        <b-col cols="12">
          <StepFormRegister :currentStep="this.currentStep" :titleListSteps="this.titleListSteps" />
        </b-col>
        <b-col xl="9" lg="9" sm="12" md="12" class="border rounded py-4 py-md-5 border-light-gray">
          <h3 class="content-header-title text-primary-dark">{{ $t('Document') }}</h3>
          <div class="mt-4">
            <label class="text-primary-dark font-weight-600">{{ $t('COMPULSORY DOCUMENTATION FOR TEACHER REGISTRATION (RTY - STANDARDS)') }}</label>
            <p class="small text-primary-dark font-italic">{{ $t('If you are unable to attach the required documents via this online application, please send it separately to contact@wyayoga.org') }}</p>
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
              <b-form-group :description="''">
                <label for="cv_experience" class="text-primary-dark font-weight-600">{{ $t('Curriculum Vitae (CV) and experience.') }}<span class="text-danger">*</span></label>
                <validation-provider
                  :name="$t('Curriculum Vitae (CV) and experience.')"
                  :rules="(!doc_cv_name ? 'required' : '')"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-file
                  id="cv_experience"
                  v-model="form.doc_cv"
                  :placeholder="$t('No file chosen')"
                  :drop-placeholder="$t('Drop file here')"
                ></b-form-file>
                 <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <small>{{ doc_cv_name }}</small>
              </b-form-group>
               <b-form-group :description="''">
                <label for="syllabus_details" class="text-primary-dark font-weight-600">{{ $t('Syllabus, Details and history of the yoga teacher training course(s).') }}<span class="text-danger">*</span></label>
                <validation-provider
                  :name="$t('Syllabus, Details and history of the yoga teacher training course(s).')"
                  :rules="(!doc_syllabus_curriculum_name ? 'required' : '')"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-file
                  id="syllabus_details"
                  v-model="form.doc_syllabus_curriculum"
                  :placeholder="$t('No file chosen')"
                  :drop-placeholder="$t('Drop file here')"
                ></b-form-file>
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <small>{{ doc_syllabus_curriculum_name }}</small>
              </b-form-group>
              <b-form-group :description="''">
                <label for="photocopy_certificates" class="text-primary-dark font-weight-600">{{ $t('Photocopy of all certificates, degree or Diploma / Documents.') }}<span class="text-danger">*</span></label>
                <validation-provider
                  :name="$t('Photocopy of all certificates, degree or Diploma / Documents.')"
                  :rules="(!doc_certificate_name ? 'required' : '')"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-file
                  id="photocopy_certificates"
                  v-model="form.doc_certificate"
                  :placeholder="$t('No file chosen')"
                  :drop-placeholder="$t('Drop file here')"
                ></b-form-file>
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <small>{{ doc_certificate_name }}</small>
              </b-form-group>
              <b-form-group md='12' lg="12" class="mb-3 text-13">
                <b-form-checkbox
                  id="accept"
                  v-model="acceptSelected"
                  name="accept"
                  value="accept"
                  unchecked-value="not_accepted"
                >
                  {{ $t('I accept the terms and use & Condition/Privacy of WYA Yoga') }} (<span><router-link :to="{ name: 'termsAndConditions' }" exact class="font-weight-bold text-primary-dark ml-2 mr-2" target="_blank">{{ $t('Terms and Conditions') }}</router-link></span> | <span> <router-link :to="{ name: 'privacyPolicy' }" exact class="font-weight-bold text-primary-dark ml-2 mr-2" target="_blank">{{ $t('Privacy Policy') }}</router-link></span>)
                </b-form-checkbox>
              </b-form-group>
              <b-row class="justify-content-center mt-4">
                <b-button type="reset" @click="onPrevious" pill class="btn-default text-primary-dark font-weight-bold px-5">{{ $t('Previous') }}</b-button>
                <b-button type="submit" :disabled="isLoading || acceptSelected == 'not_accepted'" pill class="btn-primary-dark ml-3 font-weight-bold px-5"><b-spinner small class="mr-3" v-if="isLoading"  label="Loading..."/>{{ $t('Next') }}</b-button>
              </b-row>
            </b-form>
            </ValidationObserver>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

import StepFormRegister from '../../components/form/StepFormRegister'
import Api from '../../api/api'

export default {
  name: 'TeacherRegisterDocument',
  title: ' | Teacher register document',
  components: {
    StepFormRegister
  },
  data () {
    return {
      currentStep: 3,
      titleListSteps: [this.$t('Start'), this.$t('Teacher info'), this.$t('Yoga info'), this.$t('Document'), this.$t('Finished')],
      form: {
        id: null,
        doc_cv: null,
        doc_syllabus_curriculum: null,
        doc_certificate: null
      },
      acceptSelected: 'not_accepted',
      doc_cv_name: null,
      doc_syllabus_curriculum_name: null,
      doc_certificate_name: null,
      isLoading: false
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    const user = await this.$store.getters.userProfile
    await this.$store.dispatch('getTeacher', { user: user.id })
    this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    const teacherId = localStorage.getItem('teacherId')
    if (teacherId) {
      this.getTeacher(teacherId)
    } else {
      this.$router.push({ name: 'teacherRegisterInformation' })
    }
  },
  computed: {
    teacherId () {
      return this.$store.getters.teacherId
    }
  },
  methods: {
    onSubmit () {
      this.$refs.form.validate().then(result => {
        if (result) {
          this.updateTeacher()
        }
      })
    },
    updateTeacher () {
      this.isLoading = true
      Api.updateTeacherDocument(this.form)
        .then((response) => {
          this.isLoading = false
          this.$router.push({ name: 'teacherRegisterPayment' })
        })
        .catch(() => {
          this.isLoading = false
          const textError = this.$t('Unsuccessfully register teacher')
          this.$notify({
            group: 'error',
            title: this.$t('Register teacher'),
            text: textError
          })
        })
    },
    onPrevious () {
      this.$router.push({ name: 'teacherRegisterYoga' })
    },
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    formDataTeacher () {
      Api.formDataTeacher().then(response => {
        if (response.data) {
          this.doc_cv_name = response.data.doc_cv
          this.doc_syllabus_curriculum_name = response.data.doc_syllabus_curriculum
          this.doc_certificate_name = response.data.doc_certificate
        }
      })
    },
    getTeacher (id) {
      Api.teacherDetail({ id: id }).then(response => {
        if (response) {
          this.form.id = response.data.id
          this.doc_cv_name = response.data.doc_cv
          this.doc_syllabus_curriculum_name = response.data.doc_syllabus_curriculum
          this.doc_certificate_name = response.data.doc_certificate
        }
      })
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
<style >

</style>
