var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{staticClass:"base-container-x pt-2",staticStyle:{"min-height":"400px","background-color":"#fff"}},[_c('b-row',{staticClass:"mb-5 justify-content-center"},[_c('b-col',{attrs:{"cols":"12"}},[_c('StepFormRegister',{attrs:{"currentStep":this.currentStep,"titleListSteps":this.titleListSteps}})],1),_c('b-col',{staticClass:"border rounded py-4 py-md-5 border-light-gray",attrs:{"xl":"9","lg":"9","sm":"12","md":"12"}},[_c('h3',{staticClass:"content-header-title text-primary-dark"},[_vm._v(_vm._s(_vm.$t('Document')))]),_c('div',{staticClass:"mt-4"},[_c('label',{staticClass:"text-primary-dark font-weight-600"},[_vm._v(_vm._s(_vm.$t('COMPULSORY DOCUMENTATION FOR TEACHER REGISTRATION (RTY - STANDARDS)')))]),_c('p',{staticClass:"small text-primary-dark font-italic"},[_vm._v(_vm._s(_vm.$t('If you are unable to attach the required documents via this online application, please send it separately to contact@wyayoga.org')))]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-form-group',{attrs:{"description":''}},[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"cv_experience"}},[_vm._v(_vm._s(_vm.$t('Curriculum Vitae (CV) and experience.'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":_vm.$t('Curriculum Vitae (CV) and experience.'),"rules":(!_vm.doc_cv_name ? 'required' : ''),"autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"cv_experience","placeholder":_vm.$t('No file chosen'),"drop-placeholder":_vm.$t('Drop file here')},model:{value:(_vm.form.doc_cv),callback:function ($$v) {_vm.$set(_vm.form, "doc_cv", $$v)},expression:"form.doc_cv"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('small',[_vm._v(_vm._s(_vm.doc_cv_name))])],1),_c('b-form-group',{attrs:{"description":''}},[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"syllabus_details"}},[_vm._v(_vm._s(_vm.$t('Syllabus, Details and history of the yoga teacher training course(s).'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":_vm.$t('Syllabus, Details and history of the yoga teacher training course(s).'),"rules":(!_vm.doc_syllabus_curriculum_name ? 'required' : ''),"autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"syllabus_details","placeholder":_vm.$t('No file chosen'),"drop-placeholder":_vm.$t('Drop file here')},model:{value:(_vm.form.doc_syllabus_curriculum),callback:function ($$v) {_vm.$set(_vm.form, "doc_syllabus_curriculum", $$v)},expression:"form.doc_syllabus_curriculum"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('small',[_vm._v(_vm._s(_vm.doc_syllabus_curriculum_name))])],1),_c('b-form-group',{attrs:{"description":''}},[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"photocopy_certificates"}},[_vm._v(_vm._s(_vm.$t('Photocopy of all certificates, degree or Diploma / Documents.'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":_vm.$t('Photocopy of all certificates, degree or Diploma / Documents.'),"rules":(!_vm.doc_certificate_name ? 'required' : ''),"autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"photocopy_certificates","placeholder":_vm.$t('No file chosen'),"drop-placeholder":_vm.$t('Drop file here')},model:{value:(_vm.form.doc_certificate),callback:function ($$v) {_vm.$set(_vm.form, "doc_certificate", $$v)},expression:"form.doc_certificate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('small',[_vm._v(_vm._s(_vm.doc_certificate_name))])],1),_c('b-form-group',{staticClass:"mb-3 text-13",attrs:{"md":"12","lg":"12"}},[_c('b-form-checkbox',{attrs:{"id":"accept","name":"accept","value":"accept","unchecked-value":"not_accepted"},model:{value:(_vm.acceptSelected),callback:function ($$v) {_vm.acceptSelected=$$v},expression:"acceptSelected"}},[_vm._v(" "+_vm._s(_vm.$t('I accept the terms and use & Condition/Privacy of WYA Yoga'))+" ("),_c('span',[_c('router-link',{staticClass:"font-weight-bold text-primary-dark ml-2 mr-2",attrs:{"to":{ name: 'termsAndConditions' },"exact":"","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('Terms and Conditions')))])],1),_vm._v(" | "),_c('span',[_c('router-link',{staticClass:"font-weight-bold text-primary-dark ml-2 mr-2",attrs:{"to":{ name: 'privacyPolicy' },"exact":"","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('Privacy Policy')))])],1),_vm._v(") ")])],1),_c('b-row',{staticClass:"justify-content-center mt-4"},[_c('b-button',{staticClass:"btn-default text-primary-dark font-weight-bold px-5",attrs:{"type":"reset","pill":""},on:{"click":_vm.onPrevious}},[_vm._v(_vm._s(_vm.$t('Previous')))]),_c('b-button',{staticClass:"btn-primary-dark ml-3 font-weight-bold px-5",attrs:{"type":"submit","disabled":_vm.isLoading || _vm.acceptSelected == 'not_accepted',"pill":""}},[(_vm.isLoading)?_c('b-spinner',{staticClass:"mr-3",attrs:{"small":"","label":"Loading..."}}):_vm._e(),_vm._v(_vm._s(_vm.$t('Next')))],1)],1)],1)]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }